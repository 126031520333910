import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaGithub } from 'react-icons/fa';
import { Link } from 'gatsby';

const FooterStyles = styled.footer`
  margin-top: 2rem;
  line-height: 1.5;

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
  }

  a {
    color: var(--color-primary);
  }

  .social {
    text-align: center;
  }

  @media (max-width: 750px) {
    .container {
      grid-template-columns: 1fr 1fr;
    }

    .bio {
      grid-row: 1;
      grid-column: span 2;
    }
  }
`;

const ListWithoutMarkerStyles = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-inline-start: 0;

  li {
    svg {
      vertical-align: middle;
      margin-right: 5px;
      color: #4a4656;
      &:hover {
        color: #000;
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <hr />
      <div className="container">
        <div>
          <ListWithoutMarkerStyles>
            <li>
              <Link to="/">brianbroom.com</Link>
            </li>
            <li>
              <a href="mailto:brian@brianbroom.com">brian@brianbroom.com</a>
            </li>
            <li>
              © {new Date().getFullYear()}, Built with
              {` `}
              <a href="https://www.gatsbyjs.com">Gatsby</a>
            </li>
          </ListWithoutMarkerStyles>
        </div>
        <div className="social">
          <ListWithoutMarkerStyles>
            <li>
              <FaGithub />
              <a href="https://github.com/bcbroom">bcbroom</a>
            </li>
            <li>
              <FaTwitter />
              <a href="https://twitter.com/bcbroom">bcbroom</a>
            </li>
          </ListWithoutMarkerStyles>
        </div>
        <div className="bio">
          <p>
            I'm an independent iOS Developer and Teacher. I dabble in many
            things. Currently learning Rails for API development, Bluetooth for
            hardware communication, and Sprite Kit for games.
          </p>
        </div>
      </div>
    </FooterStyles>
  );
}
