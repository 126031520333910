import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

:root {
    /* --color-primary: #005b99; */
    --color-primary: maroon;
    --color-text: #2e353f;
    --color-text-light: #4f5969;
    --color-heading: #1a202c;
    --color-heading-black: black;
    --color-accent: #d1dce5;
}
    a {
        text-decoration: none;
    }
`;

export default GlobalStyles;
